import React, { Component } from "react";
import {FaTwitter , FaBriefcase , FaInstagram , FaFacebookF } from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/RedemptionPsychiatry/'},
    {Social: <FaBriefcase /> , link: 'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=d7a56439-403d-4804-b913-8cf397a9df48&ccId=19000101_000001&type=JS&lang=en_US'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/Redemption_Psychiatry'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/redemptionpsych'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Get Started?</span>
                                        <h2>Schedule A Consult Now!</h2>
                                        <a className="rn-button-style--2" href="/contact">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                {/* <h4>Quick Links</h4> */}
                                                <br />
                                                <ul className="ft-link">
                                                    <li><a target="blank" href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=d7a56439-403d-4804-b913-8cf397a9df48&ccId=19000101_000001&type=JS&lang=en_US">Career Opportunities</a></li>
                                                    <li><a href="/privacypolicy">Privacy Policy</a></li>
                                                    <li><a href="/disclaimer">Disclaimer</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4 style={{marginBottom:"0px"}}>Email us</h4>
                                                <ul className="ft-link">
                                                    <li ><a href="mailto:tms@rpsych.com">tms@rpsych.com</a></li>
                                                </ul>
                                                <br />
                                                <h4 style={{marginBottom:"0px"}}>Call us</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:tms@rpsych.com">(480) 576-8666</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Website made by Nicholas Farrell.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;